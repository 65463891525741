import React from 'react'

export default function Social() {
  return (
    <div className="social-links text-center">
      <a href="https://opensea.io/collection/falconeyeclub" className="twitter"><i className="fa fa-soundcloud"></i></a>
      <a href="https://twitter.com/FalconEyeClub" className="twitter"><i className="fa fa-twitter"></i></a>
      <a href="https://nftcalendar.io/event/falcon-eye-club-nft-collection-presale/" className="nftcalendar"><i className="fa fa-chrome fa-lg"></i></a>
      <a href="https://www.instagram.com/falconeye.io/" className="instagram"><i className="fa fa-instagram"></i></a>
    </div>
  )
}
