import React from 'react';
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import './App.css';
import PreSale from './pages/PreSale';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/mint" element={<PreSale /> }/>
    </Routes>
  );
}

export default App;
