import React from 'react'

function About() {
  return (
    <div id="About" className="basic-1">
        <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <div className="text-container">
                        <h1 className="blue">About Us</h1>
                        <p>The Falcon Eye Club is a community-driven collectibles utility NFT project on the Ethereum Blockchain.
                        <br/><br/>
                        The Falcon Eye Club project is a decentralized autonomous organization (DAO) with a governance mechanism that allows the project to be managed by the community.
                        All members will benefit from the following:
                        <br/><br/>
                        </p>
                        <ul>
                            <li>Periodic royalties & profits redistribution</li>
                            <li>Early Access to our utilities and collectibles</li>
                            <li>Amazing Airdrops and other exclusive offers</li>
                            <li>Access to the project's development roadmap</li>
                            <li>Access to the FalconEye Discord and other community resources</li>
                        </ul>
                        <p>
                        <br/>
                        The project is currently in its Presale phase.
                        <br/><br/>
                        We are here for the long run and we want to positively contribute to the future of the blockchain ecosystem and the welfare of the community.
                        </p>
                    </div>
                </div> 
                <div className="col-lg-6">
                    <div className="image-container">
                        <img className="img-fluid" src="images/details.png" alt="alternative" />
                    </div> 
                </div> 
            </div> 
        </div>
    </div>
  )
}

export default About