import React, { useCallback, useEffect, useState } from "react";
import {useMoralis} from "react-moralis";
import { useNotification } from "web3uikit";
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';

export default function NewsLetter() {
  const { Moralis } = useMoralis();
  const dispatch = useNotification();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [token, setToken] = useState('');

  const handleSuccess= () => {
    dispatch({
      type: "success",
      message: `You have successfully subscribed to our newsletter.`,
      title: "Subscription Successful",
      position: "bottomL",
    });
  };

  const handleError= (msg) => {
    dispatch({
      type: "error",
      message: `${msg}`,
      title: "Subscription Error",
      position: "bottomL",
    });
  };

  const handleNoAccount= () => {
    dispatch({
      type: "error",
      message: `You need to connect your wallet to subscribe to our newsletter.`,
      title: "Not Connected",
      position: "bottomL",
    });
  };  
  
  useEffect(() => {
      if (!executeRecaptcha) {
          return;
      }
      const handleReCaptchaVerify = async () => {
          const token = await executeRecaptcha('register');
          setToken(token);
      };
      handleReCaptchaVerify();
  }, [executeRecaptcha]);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (!executeRecaptcha) {
      return;
    }
    const user = Moralis.User.current();
    if(user) {
      const params={
        email: e.target.email.value,
        ethAddress: user.ethAddress,
      }
      if(params.email.length > 0){  
        const newsletterResponse = await Moralis.Cloud.run("newsletter", params);
          try{
            if (newsletterResponse.success) {
              handleSuccess(newsletterResponse.result);
            }else{
              handleError(newsletterResponse.result);
            }
          }catch(error){
            handleError(error.message);
          }
      }else{
        handleError("Email is required");
      }
    }else{
      handleNoAccount();
    }

    const result = await executeRecaptcha('register');
    setToken(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [executeRecaptcha]);
    
  return (
    <div className="footer-newsletter">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-6">
          <h1 className="white text-center">Join Our Newsletter</h1>
          <form className="newsletter_form" onSubmit={handleSubmit}>
            <input type="email" placeholder="Email" name="email" id="email" />
            <input id="btn_newsletter" type="submit" value="Subscribe" />
            <input type="hidden" name="g-recaptcha-response" value={token} />
          </form>
        </div>
      </div>
    </div>
  </div>
  )
}
