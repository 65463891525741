import React from 'react'
import { useMoralis, useWeb3ExecuteFunction} from "react-moralis";
import { useState,useEffect } from 'react';
import {useNotification } from "web3uikit";
import { v4 as uuid } from 'uuid';

export default function MintForm() {
    const { isInitialized,Moralis, account } = useMoralis();
    const [balance, setBalance] = useState(0);
    const [gasFee, setGasFee] = useState(0);
    const [gasMeter, setGasMeter] = useState('.gas-mid');
    const [price, setPrice] = useState(0.011);
    const [totalNFT, setTotalNFT] = useState(1);
    const [Available, setAvailable] = useState(8499);
    const [Minted, setMinted] = useState(1501);
    const [Total, setTotal] = useState(10000);
    const contractProcessor = useWeb3ExecuteFunction();
    const dispatch = useNotification();
    const NFTPrice = 0.011;
    const contractAddress = "0xe26896ea280BD7781EB9F413C817427B7bad4aCc";

    const handleSuccess= () => {
      dispatch({
        type: "success",
        message: `Your order submitted!`,
        title: "Order Successful",
        position: "bottomR",
      });
    };
  
    const handleError= (msg) => {
      dispatch({
        type: "error",
        message: `${msg}`,
        title: "Order Failed",
        position: "bottomR",
      });
    };
  
    const handleNoAccount= () => {
      dispatch({
        type: "error",
        message: `You need to connect your wallet to submit your order.`,
        title: "Not Connected",
        position: "bottomR",
      });
    };

    const submitOrder = async function (e) {
      try {
        const orderID = String(uuid());
        console.log(orderID);
        const quantity = e.target[0].value;
        let options = {
          contractAddress: contractAddress,
          functionName: "submitOrder",
          abi: [
            {
              "inputs": [
                {
                  "internalType": "string",
                  "name": "orderId",
                  "type": "string"
                },
                {
                  "internalType": "string",
                  "name": "inventoryType",
                  "type": "string"
                },
                {
                  "internalType": "uint256",
                  "name": "quantity",
                  "type": "uint256"
                }
              ],
              "name": "submitOrder",
              "outputs": [],
              "stateMutability": "payable",
              "type": "function"
            }
          ],
          params: {
            orderId: orderID,
            inventoryType: "FalconEyeV1",
            quantity: quantity,  
          },
          msgValue: String(Moralis.Units.ETH(quantity*NFTPrice)),
        };
        await contractProcessor.fetch({
          params: options,         
          onSuccess: () => {
            handleSuccess();
          },
          onError: (error) => {
            console.log(error.code);
            if (error.toString().includes("UNPREDICTABLE_GAS_LIMIT")) {
              handleError("Cannot estimate gas; transaction may fail or may require manual gas limit");
            }else if(error.code === "INSUFFICIENT_FUNDS"){
              handleError("Insufficient funds for intrinsic transaction cost");
            }else if(error.code === 4001){
              handleError("You have denied transaction signature");
            }else {
              handleError("Something went wrong, please try again later on");
            }
          },
        }); 
      } catch (error) {
        handleError(error.message);
      }  
    }


    const submitbatch = async function (e) {
      try {
        const orderID = String(uuid());
        console.log(orderID);
        const quantity = e.target[0].value;
        let options = {
          contractAddress: contractAddress,
          functionName: "submitOrder",
          abi: [
            {
              "inputs": [
                {
                  "internalType": "string",
                  "name": "orderId",
                  "type": "string"
                },
                {
                  "internalType": "string",
                  "name": "inventoryType",
                  "type": "string"
                },
                {
                  "internalType": "uint256",
                  "name": "quantity",
                  "type": "uint256"
                }
              ],
              "name": "submitOrder",
              "outputs": [],
              "stateMutability": "payable",
              "type": "function"
            }
          ],
          params: {
            orderId: orderID,
            inventoryType: "FalconEyeV1",
            quantity: quantity,  
          },
          msgValue: String(Moralis.Units.ETH(quantity*NFTPrice)),
        };
        await contractProcessor.fetch({
          params: options,         
          onSuccess: () => {
            handleSuccess();
          },
          onError: (error) => {
            console.log(error.code);
            if (error.toString().includes("UNPREDICTABLE_GAS_LIMIT")) {
              handleError("Cannot estimate gas; transaction may fail or may require manual gas limit");
            }else if(error.code === "INSUFFICIENT_FUNDS"){
              handleError("Insufficient funds for intrinsic transaction cost");
            }else if(error.code === 4001){
              handleError("You have denied transaction signature");
            }else {
              handleError("Something went wrong, please try again later on");
            }
          },
        }); 
      } catch (error) {
        handleError(error.message);
      }  
    }
    
    
    const updateInventory = async function (e) {
      try {
        const orderID = String(uuid());
        console.log(orderID);
        const quantity = e.target[0].value;
        let options = {
          contractAddress: contractAddress,
          functionName: "setInventoryTypeConfig",
          abi: [
            {
              "inputs": [
                {
                  "internalType": "string",
                  "name": "orderId",
                  "type": "string"
                },
                {
                  "internalType": "string",
                  "name": "inventoryType",
                  "type": "string"
                },
                {
                  "internalType": "uint256",
                  "name": "quantity",
                  "type": "uint256"
                }
              ],
              "name": "submitOrder",
              "outputs": [],
              "stateMutability": "payable",
              "type": "function"
            }
          ],
          params: {
            orderId: orderID,
            inventoryType: "FalconEyeV1",
            quantity: quantity,  
          },
          msgValue: String(Moralis.Units.ETH(quantity*NFTPrice)),
        };
        await contractProcessor.fetch({
          params: options,         
          onSuccess: () => {
            handleSuccess();
          },
          onError: (error) => {
            console.log(error.code);
            if (error.toString().includes("UNPREDICTABLE_GAS_LIMIT")) {
              handleError("Cannot estimate gas; transaction may fail or may require manual gas limit");
            }else if(error.code === "INSUFFICIENT_FUNDS"){
              handleError("Insufficient funds for intrinsic transaction cost");
            }else if(error.code === 4001){
              handleError("You have denied transaction signature");
            }else {
              handleError("Something went wrong, please try again later on");
            }
          },
        }); 
      } catch (error) {
        handleError(error.message);
      }  
    }    

    useEffect(() => {
        const fetchInventory = async () => {
        try {
          const data = await Moralis.Cloud.run("getInventory")
          setAvailable(data.available);
          setMinted(data.minted);
          setTotal(data.total);   
        } catch (error) {
          console.log(error);
        }
      }
      if (isInitialized) {
        fetchInventory();
      }
    }, [Moralis.Cloud, isInitialized]);
  

    useEffect(() => {
      const fetchGas = async () => {
        try {
          fetch('https://api.etherscan.io/api?module=gastracker&action=gasoracle&apikey=V9BWRHNHZ4BEC9G1TSDTWBCNKTCUC7EYFM')
          .then(res => res.json())
          .then(data => {
            setGasFee(data.result.ProposeGasPrice);
            if(data.result.ProposeGasPrice < 40){
              setGasMeter('gas-low');
            }else if(data.result.ProposeGasPrice < 80){
              setGasMeter('gas-mid');
            }
            else{
              setGasMeter('gas-high');
            }
          })

        } catch (error) {
          console.log(error);
        } 
      }
      if (isInitialized) {
        fetchGas();
      }
    }, [isInitialized]);

    const ChangeTotalNFTHandler = (e) => {
      if(e.target.value <= Available && e.target.value > 0) {
        setPrice(parseFloat(e.target.value)*NFTPrice);
        setTotalNFT(e.target.value);
      }
    }

    const SubmitNFTFormHandler = (e) => {
      e.preventDefault();
      if(account){
        submitOrder(e);
      }else{
        handleNoAccount();
      }
    }

    useEffect(() => {
        async function getBalance() {
        try {
            const options = { chain: Moralis.Chains.ETH_MAINET, address: account };
            const balance = await Moralis.Web3API.account.getNativeBalance(options);
            setBalance((balance.balance / 10 ** 18).toFixed(8));
          } catch {}
        }
        if(isInitialized)
          getBalance();          
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, isInitialized]);

  return (
    <>
    <br /><br />
    <header id="header" className="mint-header">
      <div id="Mint">
        <div className="container">
          <div className="gray-box">
            <div className="text-center details">
              <div className="container">
                <div className="row">
                  <div className="col-md-4 pking">
                    <img
                      className="img-fluid"
                      src="images/falcon.gif"
                      alt="Falcon Eye Club"
                    />
                  </div>
                  <div className="col-md-8 nking">
                    <div className="container">
                      <div className="row">
                        <div className="left-p">
                          <h2 className="title-blue">Falcon Eye Club</h2>
                          <p>
                            The first official NFT Collection of Falcon Eye Club
                            with 10,000 NFT at 0.05 ETH
                          </p>
                        </div>
                        <div className="col-md-4 col-6 ibox">
                          <div className="box">
                            <p className="text-blue">Available</p>
                            <span className="bold-king">{Available}</span> <br />
                          </div>
                        </div>
                        <div className="col-md-4 col-6 ibox">
                          <div className="box">
                            <p className="text-blue">Minted</p>
                            <span className="bold-king">{Minted}</span> <br />
                          </div>
                        </div>
                        <div className="col-md-4 col-6 ibox">
                          <div className="box">
                            <p className="text-blue">Total</p>
                            <span className="bold-king">{Total}</span> <br />
                          </div>
                        </div>
                        <form className="king-form" onSubmit={SubmitNFTFormHandler}>
                          <div className="row">
                            <div className="col-md-4">
                              <span>Number of NFT</span>
                              <div className="field-king">
                                <input onChange={ChangeTotalNFTHandler} type="number" name="totalNFT" id="totalNFT" placeholder="1"  value={totalNFT} min="1" step="1"/>
                              </div>
                            </div>
                            <div className="col-md-8">
                              <span>Balance: {balance} ETH</span>
                              <div className="field-king">
                                <input
                                  type="text"
                                  placeholder="0.05 ETH"
                                  name="Price"
                                  id="Price"
                                  value={price}
                                  onChange={ChangeTotalNFTHandler}
                                />
                                <div className="gas">Gas fee: <strong className={gasMeter}>{gasFee} gwei</strong> </div>
                              </div>
                            </div>
                            <input className="Mint-btn" type="submit" value="Mint" />
                          </div>
                          </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    </>
  );
}