import React from 'react'

function PreSaleClosed() {
 const styleOpen =  { fontSize : '24px',paddingRight: '10px'};
 const css_padding = {paddingTop: '16px'}
  return (
    <div id="Pre-sale" className="basic-2">
    <div className="container">
        <div className="row">
            <div className="col-lg-5">
                <div className="image-container">
                <img className="img-fluid" src="images/project.png" alt="whitelist"/><br/><br/>
                </div> 
            </div> 
            <div className="col-lg-7">
                <div className="text-container">
                    <h1 className="blue">Public Sales</h1>
                    <p>Falcon Eye Club NFTs are now available in the OpenSea marketplace.</p>
                    <p>Invest in the fastest growing and revolutionary NFTs and gain access to great utilities and collectibles</p> 
                </div> 
                <div className="text-center" style={css_padding}>
                    <a className="king_wallet btn-airdrop" href="https://opensea.io/falconeyeclub" target="_blank" rel="noreferrer">
                       <i className="fa fa-safari" style={styleOpen}></i>
                       Purchase on OpenSea
                    </a>
                </div>
            </div>
        </div> 
    </div> 
</div> 
  )
}

export default PreSaleClosed