import React from 'react'

function MintMessage() {
  return (
<div id="Pre-sale" className="basic-2">
  <div className="container">
    <div className="row">  
      <div className="col-lg-12">
        <div className="text-container">
          <center>
          <img className="img-fluid" src="images/important.png" alt="whitelist" /><br /><br />
          <h1 className="red">Important Message</h1>
          <p>You can revisit this page anytime to view your collection or to check your order status</p>
          </center>
        </div> 
      </div>
    </div> 
  </div> 
</div> 
  )
}

export default MintMessage