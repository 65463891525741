import React from 'react'
import { useState, useEffect } from "react";
import {useMoralis} from "react-moralis";


function Orders() {
    const [orders, setOrders] = useState();
    const {isInitialized,Moralis } = useMoralis();
    
    useEffect(() => {   
        async function fetchOrders() {    
            const user = Moralis.User.current();
            if(user) {
                const Orders = Moralis.Object.extend("OrderSubmitted");
                const query = new Moralis.Query(Orders);
                query.equalTo("buyer", user.get('ethAddress'));
                const result = await query.find();
                const orders = result.map(order => {
                    return {
                        blockNumber: order.get('block_number'),
                        inventoryType: order.get('inventoryType'),
                        price: Moralis.Units.FromWei(order.get('price')),
                        quantity: order.get('quantity'),
                        confirmed: order.get('confirmed'),
                        createdAt: order.get('createdAt').toLocaleString(),
                        updatedAt: order.get('updatedAt').toLocaleString(),
                    }
                });
                setOrders(orders);
            }
        }
        if (isInitialized) {
            fetchOrders();
        }
    }, [isInitialized,Moralis]);

  return (
    <div id="details">
        <div className="container">
            <div className="row">
                <div className="col-12 section-title">
                    <center>
                        <h2>Orders</h2>
                    </center>
                </div>
                {!orders && (
                    <div className="col-12 ">
                        <center>
                        <img className="img-fluid" src="images/radar.gif" alt="whitelist" />
                         <p><b>No Order Found</b></p>
                        </center>
                     </div>
                )}
                {orders && (
                    <div className="col-12 ">
                        <table className="table table-striped table-hover">
                            <thead>
                            <tr>
                                <th scope="col">Block Number</th>
                                <th scope="col">Quantity</th>
                                <th scope="col">Price (ETH)</th>
                                <th scope="col">Date</th>
                                <th scope="col">Status</th>
                            </tr>
                            </thead>
                            <tbody>
                        {orders.map((order, index) => (
                            <tr key={index}>
                                <td>{order.blockNumber}</td>
                                <td>{order.quantity}</td>
                                <td>{order.price}</td>
                                <td>{order.createdAt}</td>
                                <td>{order.confirmed?"Completed":"Pending"}</td>
                            </tr>
                        ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    </div>
    )
}

export default Orders