import React from 'react'
import NFTCard from './NFTCard'
import { useState, useEffect } from "react";
import {useMoralis,useMoralisWeb3Api} from "react-moralis";

export default function NFT() {
  const [userNFT, setUserNFT] = useState();
  const { isInitialized } = useMoralis();
  const Web3Api = useMoralisWeb3Api();

  useEffect(() => {
    const fetchNFTs = async () => {
        const userEthNFTs = await Web3Api.Web3API.account.getNFTs({
          chain: "mainnet"
        });

        const userEthNFTsClean = userEthNFTs.result.map(nft => {
          return {
            amount: nft.amount,
            block_number: nft.block_number,
            block_number_minted: nft.block_number_minted,
            contract_type: nft.contract_type,
            last_metadata_sync: nft.last_metadata_sync,
            last_token_uri_sync: nft.last_token_uri_sync,
            metadata: nft.metadata,
            name: nft.name,
            owner_of: nft.owner_of,
            symbol: nft.symbol,
            synced_at: nft.synced_at,
            token_address: nft.token_address,
            token_hash: nft.token_hash,
            token_id: nft.token_id,
            token_uri: nft.token_uri
          };
        });
        setUserNFT(userEthNFTsClean);

    };
    if (isInitialized) {
        fetchNFTs();
        
    }
    }, [Web3Api.Web3API.account, Web3Api.account, isInitialized]);
  
  return (

    <div id="details">
     <div className="container">
      <div className="row">
        <div className="col-12 section-title">
          <h2>Collections</h2>
        </div>    
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            {userNFT && userNFT.map(nft => {
                return (
                    <NFTCard
                        key={nft.token_id}
                        nft={nft}
                    />
                );
            })}
           </div>
            {!userNFT && (
                <div className="col-12">
                    <center>
                    <img className="img-fluid" src="images/nft.gif" alt="whitelist" />
                    <p><b>No NFT Found</b></p>     
                    </center>
                </div>
            )}       
        </div>
      </div>
    </div>
</div>
  )
}
