import React,{useEffect,useState} from 'react'
import {useMoralis} from "react-moralis";

export default function NFTCard({nft}) {
  const [nftData, setNftData] = useState({});
  const [nftImage, setNftImage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const {isInitialized } = useMoralis();

  const trait = {
    "Background": {
        "Heaven": "2.52%",
        "Light Blue": "12.05%",
        "Light Gray": "12.63%",
        "Light Green": "12.69%",
        "Light Purple": "12.66%",
        "Light Violet": "12.88%",
        "Light Yellow": "12.11%",
        "Orange": "12.45%",
    },
    "Body": {
        "Beige": "15.81%",
        "Blue": "16.56%",
        "Green": "16.97%",
        "Heaven ": "17.05%",
        "Pink": "17.16%",
        "Violet": "16.44%",
    },
    "Clothes": {
        "Beige tank top": "3.84%",
        "Black Suit": "3.58%",
        "Black Tank Top": "3.92%",
        "Blue tank top": "3.85%",
        "Blue T_Shirt": "3.97%",
        "Brown Pullover": "3.77%",
        "Brown Suit": "4.05%",
        "Bshti": "3.71%",
        "Clown Dress": "3.77%",
        "Doctor Suit": "4.08%",
        "Gold Suit": "3.43%",
        "King Cloak": "3.95%",
        "Navy Jacket": "4.27%",
        "Navy Pullover": "3.88%",
        "Navy T_Shirt": "3.69%",
        "Necklace": "3.99%",
        "Orang Tank Top": "4.00%",
        "Police Jacket": "3.67%",
        "Prison": "4.05%",
        "Red Jacket": "3.68%",
        "Red Tank Top": "3.95%",
        "Thoub": "3.83%",
        "T_Shirt": "3.87%",
        "Violet tank top": "3.70%",
        "White Suit": "3.87%",
        "White Tank Top": "3.61%",
    },
    "Eyes": {
        "Blue Eyeglasses": "5.34%",
        "Clown Eyes": "5.20%",
        "Dollar": "5.04%",
        "Eyeglasses": "5.00%",
        "Fire": "4.94%",
        "Green Eyes": "4.87%",
        "Happy": "4.55%",
        "Hawk Eyes": "4.68%",
        "Huffy": "4.79%",
        "Laser": "4.97%",
        "Love": "4.79%",
        "one eye": "5.17%",
        "Pirate": "5.15%",
        "Red Eyeglasses ": "5.13%",
        "Stars": "5.09%",
        "Sunglasses": "4.74%",
        "Vertigo": "5.34%",
        "VR": "4.97%",
        "Winking": "5.36%",
        "Zombie": "4.89%",
    },
    "Hat": {
        "Angel": "5.74%",
        "Bandages": "5.86%",
        "Black Cap": "5.25%",
        "Black Hat": "5.44%",
        "Blue Cap": "5.35%",
        "Chamakh": "5.25%",
        "Clown Hair": "5.94%",
        "Crown": "5.65%",
        "Gold Hat": "5.39%",
        "Head Tie": "5.58%",
        "Headphone": "5.35%",
        "Japanese": "5.45%",
        "Mohawk": "5.72%",
        "Pirate": "5.49%",
        "Police Hat": "5.71%",
        "Red Cap": "5.75%",
        "Rice Hat": "5.57%",
        "Sailor": "5.52%",
    },
    "Mouth": {
        "Beak": "6.18%",
        "Bubblegum": "6.25%",
        "Candy": "6.54%",
        "Clown Nose": "6.55%",
        "Earthworm": "7.05%",
        "Fish": "6.65%",
        "Golden Teeth": "6.76%",
        "Gray Beak": "7.25%",
        "occurrence": "6.71%",
        "Vomiting": "6.75%",
    }
};

let traitValues = Object.values(trait);
let traitBackgroundKeys = Object.keys(traitValues[0]);
let traitBackgroundValues = Object.values(traitValues[0]);
let traitBodyKeys = Object.keys(traitValues[1]);
let traitBodyValues = Object.values(traitValues[1]);
let traitClothesKeys = Object.keys(traitValues[2]);
let traitClothesValues = Object.values(traitValues[2]);
let traitEyesKeys = Object.keys(traitValues[3]);
let traitEyesValues = Object.values(traitValues[4]);
let traitHatKeys = Object.keys(traitValues[4]);
let traitHatValues = Object.values(traitValues[4]);
let traitMouthKeys = Object.keys(traitValues[5]);
let traitMouthValues = Object.values(traitValues[5]);

  
  useEffect(() => {
    const fetchNFTData = async () => {
      try {
        const nftURL = `https://nft.falconeye.io/${nft.token_id}.json`;
        setNftImage(`https://nft.falconeye.io/${nft.token_id}.png`);
  
        const response = await fetch(nftURL, {
          method: "GET",
        });
       
        const data = await response.json();
        setNftData(data);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };    
    if (isInitialized) {
      fetchNFTData();
  }
}, [isInitialized,nft]);


    if (isLoading) {
      return <div className="col-12 ">
      <img className="img-fluid" src="images/clock.gif" alt="whitelist" />
      <p><b>Loading...</b></p>
    </div>;
    }
    if (error) {
      return <></>;
    }

  return (
  <>
    <div className="col-md-4 col-12 w-50 mx-auto" >
    <div className="gray-box mt-2 mb-2">
      <div className="text-center details">
        <div className="col-12 pking"><img className="img-fluid" src={nftImage} alt="Falcon Eye Club" /></div>
        <div className="container">
          <div className="row mt-2">
            <div className="col-6 ibox">
              <div className="box">
                <p className="text-blue">Background</p>
                <span className="bold-king">{nftData.attributes[0].value}</span> <br />
                <span className="small-text">{traitBackgroundValues[traitBackgroundKeys.indexOf(nftData.attributes[0].value)]} have this trait</span>
              </div>
            </div>
            <div className="col-6 ibox">
              <div className="box">
                <p className="text-blue">Body</p>
                <span className="bold-king">{nftData.attributes[1].value}</span> <br />
                <span className="small-text">{traitBodyValues[traitBodyKeys.indexOf(nftData.attributes[1].value)]} have this trait</span>
              </div>
            </div>
            <div className="col-6 ibox">
              <div className="box">
                <p className="text-blue">Clothes</p>
                <span className="bold-king">{nftData.attributes[2].value}</span> <br />
                <span className="small-text">{traitClothesValues[traitClothesKeys.indexOf(nftData.attributes[2].value)]} have this trait</span>
              </div>
            </div>
            <div className=" col-6 ibox">
              <div className="box">
                <p className="text-blue">Eyes</p>
                <span className="bold-king">{nftData.attributes[3].value}</span> <br />
                <span className="small-text">{traitEyesValues[traitEyesKeys.indexOf(nftData.attributes[3].value)]} have this trait</span>
              </div>
            </div>
            <div className="col-6 ibox">
              <div className="box">
                <p className="text-blue">Hat</p>
                <span className="bold-king">{nftData.attributes[4].value}</span> <br />
                <span className="small-text">{traitHatValues[traitHatKeys.indexOf(nftData.attributes[4].value)]} have this trait</span>
              </div>
            </div>
            <div className=" col-6 ibox">
              <div className="box">
                <p className="text-blue">Mouth</p>
                <span className="bold-king">{nftData.attributes[5].value}</span> <br />
                <span className="small-text">{traitMouthValues[traitMouthKeys.indexOf(nftData.attributes[5].value)]} have this trait</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  </>
  )
}
