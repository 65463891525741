import React from 'react'

function RoadMap() {
  return (
    <div id="roadmap" className="basic-1">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h1 className="blue text-center">ROADMAP</h1>
            <section id="cd-timeline" className="cd-container">
              <div className="cd-timeline-block">
                <div className="cd-timeline-img cd-picture">
                </div>
  
                <div className="cd-timeline-content">
                  <div className="timeline-content-info">
                    <span className="timeline-content-info-date">&nbsp;
                      <i className="fa fa-calendar-o" aria-hidden="true"></i>
                      May 2022
                    </span>
                  </div>
                  <h2>The Calm Before the Launch</h2>
                  <ul className="king-roadmap">
                    <li>Design and creation of<b> 10+ unique traits</b></li>
                    <li>Instagram and Twitter Launch</li>
                    <li>Presale on the whitelist</li>
                    <li>Launch of the website</li>
                  </ul>
                </div> 
              </div> 
  
              <div className="cd-timeline-block">
                <div className="cd-timeline-img cd-movie">
                </div> 
  
                <div className="cd-timeline-content">
                  <div className="timeline-content-info">
                    <span className="timeline-content-info-date">&nbsp;
                      <i className="fa fa-calendar-o" aria-hidden="true"></i>
                      Jun 2022
                    </span>
                  </div>
                  <h2>Launch Initiated​</h2>
                  <ul className="king-roadmap">
                    <li>Release 10,000 first-gen Falcon Eye NFT into the Ethereum ecosystem</li>
                    <li>Launch of the public sale</li>
                    <li>Launch of the Community Wallet</li>
                    <li>Donation of part of the Mint Profits to the charity and 20% in the Community Wallet</li>
                    <li>First Profits redistribution to the community</li>
                  </ul>
                </div> 
              </div> 
  
              <div className="cd-timeline-block">
                <div className="cd-timeline-img cd-picture">
                </div> 
  
                <div className="cd-timeline-content">
                  <div className="timeline-content-info">
                    <span className="timeline-content-info-date">&nbsp;
                      <i className="fa fa-calendar-o" aria-hidden="true"></i>
                      Aug 2022
                    </span>
                  </div>
                  <h2>Onto The Next One</h2>
                  <ul className="king-roadmap">
                    <li>Lunch of <b>Discord Server </b>to the club members and the community</li> 
                    <li>Publish the DAO decision making and governance mechanism</li>
                    <li>Release of the Falcon Eye <b>Development Roadmap</b> for 2022 and 2023</li>
                    <li>Second Profits redistribution to our Falconers and the club wallets</li>
                  </ul>
                </div> 
              </div> 
  
              <div className="cd-timeline-block">
                <div className="cd-timeline-img cd-location">
                </div> 
  
                <div className="cd-timeline-content">
                  <div className="timeline-content-info">
                    <span className="timeline-content-info-date">&nbsp;
                      <i className="fa fa-calendar-o" aria-hidden="true"></i>
                      Oct 2022
                    </span>
                  </div>
                  <h2>Consolidation and Expansion</h2>
                  
                  <ul className="king-roadmap">
                    <li>Use <b>the Falcon DAO</b> community-backed organization to support the decided business
                      investments and ventures</li>
                    <li>Introducing Falcon Eye  <b>Mobile App</b> and <b>Marketplace</b> for the community</li>
                    <li>New and expanded utilities and services for the club</li>
                    <li>First giveaways and Falcon Prizes with <b>falcon Eye Merchandized</b> like hoodies, mugs and
                      shirts</li>
                  </ul>
                  
                </div> 
              </div> 
              <div className="cd-timeline-block">
                <div className="cd-timeline-img cd-location">
                </div>
  
                <div className="cd-timeline-content">
                  <div className="timeline-content-info">
                    <span className="timeline-content-info-date">&nbsp;
                      <i className="fa fa-calendar-o" aria-hidden="true"></i>
                      Dec 2022
                    </span>
                  </div>
                  <h2>Final Frontiers</h2>
                  <ul className="king-roadmap">
                    <li>The Falcon Eye Club set claws on the <b>Metaverse</b></li>
                    <li>Release of First Falcon Eye <b>learn to earn game</b> for the community</li>
                    <li> <b>Multi-chain</b> expansion to increase the Club reach and investment opportunity</li>
                    <li>Beta Release of an innovative cross-chain <b>Utilities</b> powered by Machine Learning </li>
                    <li>First <b>VR Event</b> for Falcon Eye Club</li>
                    <li>Third Profits redistribution to the community</li>
                  </ul>
                </div> 
              </div>   
            </section>
            </div> 
        </div> 
      </div> 
    </div> 

  )
}

export default RoadMap